import "../assets/css/desktop.css";
import "../assets/css/fontText.css";
import LOGO from "../assets/image/logo.png";
import TITLE from "../assets/image/title.png";
import BG_REWARD from "../assets/image/bg-reward.png";
import IPHONE from "../assets/image/iphone15.png";
import NHAN from "../assets/image/nhan.png";
import SEEMORE from "../assets/image/seemore.png";
import BTN_ROTATION from "../assets/image/button-rotation.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { homeServices } from "../services/apiService/homeService";
import { ListRewardsModel } from "../models/ListRewardsModel";
import { URL_SPIN } from "../services/localService/localService";
import { setAuthorization } from "../services/apiService/configURL";

export default function YearAndParty() {
  const navigation = useNavigate();
  const handleDetails = (id: number) => {
    navigation(`/details-reward/yep/${id}`);
  };
  const token = localStorage.getItem("TOKEN");
  const location = useLocation();
  const pathUrl = location.pathname.split("/")[1];
  console.log(location.pathname);

  const [lstRewards, setLstRewards] = useState<any>();
  useEffect(() => {
    localStorage.setItem("PATH", "yep");

    setAuthorization(token as string);

    homeServices
      .ListRewards(location.pathname)
      .then((res: any) => {
        console.log(res);
        setLstRewards(res?.lst_rewards?.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="bg-desktop relative">
      {lstRewards && (
        <div className="relative top-44 grid grid-cols-4 mt-0 px-20">
          {lstRewards?.map((res: any, index: number) => {
            return (
              <div className="col-span-1 flex justify-center">
                <div className="w-full relative">
                  <div className="flex justify-center relative top-1/2 -translate-y-1/2">
                    <img
                      src={BG_REWARD}
                      className="w-8/12 2xl:w-11/12 min-[2000px]:w-6/12"
                    />

                    <div className="absolute top-14">
                      <div className="relative left-1/2 -translate-x-1/2 w-6/12 sm:w-5/12 2xl:w-7/12 min-[2000px]:w-6/12">
                        <img src={res?.gift_image} className="" />
                        <div className="w-full text-center flex flex-col items-center mt-5">
                          <div className="font-bold-italic-so text-[22px]">
                            {res?.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!res?.check_code_validate && (
                    <button
                      className="absolute bottom-4 w-full flex justify-center 2xl:-bottom-5 min-[2000px]:bottom-4"
                      onClick={() => handleDetails(res?.id)}
                    >
                      <img src={SEEMORE} className="w-8/12" />
                    </button>
                  )}
                </div>
              </div>
            );
          })}

          {/* <div className="col-span-1 flex justify-center">
            <div className="w-full relative">
              <div className="flex justify-center relative top-1/2 -translate-y-1/2">
                <img
                  src={BG_REWARD}
                  className="w-8/12 2xl:w-11/12 min-[2000px]:w-6/12"
                />

                <div className="absolute top-10">
                  <div className="relative left-1/2 -translate-x-1/2 w-6/12 sm:w-5/12 2xl:w-6/12 min-[2000px]:w-6/12">
                    <img src={lstRewards[2]?.gift_image} className="" />
                    <div className="w-full text-center flex flex-col items-center">
                      <div className="font-bold-italic-so text-[21px]">
                        {lstRewards[2]?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!lstRewards[2]?.check_code_validate && (
                <button
                  className="absolute -bottom-10 w-full flex justify-center 2xl:-bottom-14 min-[2000px]:bottom-4"
                  onClick={() => handleDetails(lstRewards[2]?.id)}
                >
                  <img src={SEEMORE} className="w-8/12" />
                </button>
              )}
            </div>
          </div>
          <div className="col-span-1 flex justify-center">
            <div className="w-full relative">
              <div className="flex justify-center relative top-1/2 -translate-y-1/2">
                <img
                  src={BG_REWARD}
                  className="w-8/12 2xl:w-11/12 min-[2000px]:w-6/12"
                />
                <div className="absolute top-10">
                  <div className="relative left-1/2 -translate-x-1/2 w-6/12 sm:w-5/12 2xl:w-6/12 min-[2000px]:w-6/12">
                    <img src={lstRewards[1]?.gift_image} className="" />
                    <div className="w-full text-center flex flex-col items-center">
                      <div className="font-bold-italic-so text-[21px]">
                        {lstRewards[1]?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!lstRewards[1]?.check_code_validate && (
                <button
                  className="absolute -bottom-10 w-full flex justify-center 2xl:-bottom-14 min-[2000px]:bottom-4"
                  onClick={() => handleDetails(lstRewards[1]?.id)}
                >
                  <img src={SEEMORE} className="w-8/12" />
                </button>
              )}
            </div>
          </div>
          <div className="col-span-1 flex justify-center">
            <div className="w-full relative">
              <div className="flex justify-center relative top-1/2 -translate-y-1/2">
                <img
                  src={BG_REWARD}
                  className="w-8/12 2xl:w-11/12 min-[2000px]:w-6/12"
                />
                <div className="absolute top-10">
                  <div className="relative left-1/2 -translate-x-1/2 w-6/12 sm:w-5/12 2xl:w-6/12 min-[2000px]:w-6/12">
                    <img src={lstRewards[0]?.gift_image} className="" />
                    <div className="w-full text-center flex flex-col items-center">
                      <div className="font-bold-italic-so text-[21px]">
                        {lstRewards[0]?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!lstRewards[0]?.check_code_validate && (
                <button
                  className="absolute -bottom-5 w-full flex justify-center 2xl:-bottom-14 min-[2000px]:bottom-4"
                  onClick={() => handleDetails(lstRewards[0]?.id)}
                >
                  <img src={SEEMORE} className="w-8/12" />
                </button>
              )}
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
}
