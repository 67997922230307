import LOGO from "../assets/image/logo.png";
import TITLE from "../assets/image/title.png";
import BG_REWARD from "../assets/image/bg-reward.png";
import DISABLE from "../assets/image/disablebg.png";
import PLAY from "../assets/image/seemore.png";
import FINISH from "../assets/image/finished.png";
import RESULT from "../assets/image/result.png";
import BG_POINT from "../assets/image/bg-point.png";
import { useNavigate, useParams } from "react-router-dom";
import BUTTON_ROTATION from "../assets/image/button-rotation.png";
import BACK from "../assets/image/back.png";
import BTN_COUNT from "../assets/image/button-count.png";
import { Fragment, useEffect, useState } from "react";
import { homeServices } from "../services/apiService/homeService";
import { ReponseDetailsSpinModel } from "../models/ListRewardsModel";
import Popup from "../components/Popup";
import SAMPLING from "../assets/image/sampling.png";
import { setAuthorization } from "../services/apiService/configURL";
import { URL_SPIN } from "../services/localService/localService";

export default function DetailsRewardYEP() {
  const token = localStorage.getItem("TOKEN");
  const { id, type } = useParams();
  const url_spin = URL_SPIN + "/" + type;
  const navigation = useNavigate();
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [disableAll, setDisableAll] = useState<number>(0);
  const [disable, setDisable] = useState(false);
  const [spinDetails, setSpinDetails] = useState<ReponseDetailsSpinModel>();
  const [wheelRemaining, setWheelRemaining] = useState<number>(0);
  const [lstReward, setLstRewards] = useState<any>();
  const [period, setPeriod] = useState<any>();
  const handleDetails = () => {
    setAuthorization(token as string);
    setDisable(true);
    let lastNumber1 = 0;
    let lastNumber2 = 0;
    let lastNumber3 = 0;
    if (!disable) {
      let numbers1 = [
        0, 4, 3, 3, 5, 8, 6, 3, 4, 5, 2, 1, 8, 9, 8, 4, 6, 8, 7, 8, 0, 3, 2, 6,
        5, 1, 2, 3, 5, 7, 8, 9, 6, 1, 7,
      ];
      let numbers2 = [
        6, 1, 2, 3, 4, 5, 4, 3, 2, 1, 2, 3, 5, 7, 8, 4, 6, 8, 9, 0, 1, 2, 4, 3,
        2, 1, 2, 3, 5, 7, 8, 4, 6, 8, 9,
      ];
      let numbers3 = [
        8, 2, 6, 3, 2, 4, 7, 6, 5, 3, 0, 5, 4, 8, 4, 6, 8, 8, 7, 5, 3, 0, 1, 2,
        1, 2, 7, 5, 0, 1, 2, 6, 9, 1, 8,
      ];

      homeServices
        .UpdateVq2Rewards(parseInt(id as string), "/yep/random")
        .then((res: any) => {
          console.log(res);
          setDisableAll(res?.result?.amount_remaining);
          lastNumber1 = res?.result?.coded[0];
          numbers1.push(lastNumber1);

          lastNumber2 = res?.result?.coded[1];
          numbers2.push(lastNumber2);

          lastNumber3 = res?.result?.coded[2];
          numbers3.push(lastNumber3);
          setPeriod(res?.result?.period);
          setWheelRemaining(res?.result?.amount_remaining);
          let index = 0;
          let interval = setInterval(() => {
            setCount1(numbers1[index]);
            setCount2(numbers2[index]);
            setCount3(numbers3[index]);
            index++;
            if (index === numbers1.length) {
              clearInterval(interval);
            }
          }, 3000 / (numbers1.length - 1));
          setTimeout(() => {
            setDisable(false);
            setLstRewards(res?.result?.lst_rewared);
          }, 3000);
          return () => clearInterval(interval);
        })
        .catch((err) => {
          console.log(err);
        });

      console.log(1);
    }
  };

  useEffect(() => {
    setAuthorization(token as string);
    homeServices
      .DetailsRewards(id as string)
      .then((res: any) => {
        console.log(res);
        setSpinDetails(res?.result);
        setWheelRemaining(res?.result?.amount_remaining);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    homeServices
      .DetailsPeriodRewards(id as string)
      .then((res: any) => {
        setLstRewards(res?.result?.lst_rewared);
        console.log(res);
        setPeriod(res?.result?.period);
        setDisableAll(res?.result?.amount_remaining);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleBack = () => {
    navigation(`/yep/random`);
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenResult = () => {
    setIsOpen(!isOpen);
  };
  const handleFinished = () => {};
  return (
    <Fragment>
      {!isOpen ? (
        <div className={`bg-desktop relative`}>
          <div className="absolute top-10 px-14 flex justify-between w-full">
            <div className="flex items-center">
              <button className="w-16" onClick={handleBack}>
                <img src={BACK} />
              </button>
              <div
                className={`${"text-[#14A5A5]"} ml-2 uppercase font-semibold-so text-[22px]`}
              >
                Trở về
              </div>
            </div>
          </div>
          <div className="absolute top-[55%] -translate-y-1/2 left-40 w-[330px]">
            <img src={SAMPLING} />
            <div className="absolute left-[22px] top-[45%] -translate-y-1/2 flex w-9/12">
              <div className="w-1/2 -mr-2">
                <div className="w-[110px] h-[340px] text-[140px] bg-white rounded-xl font-semibold-tek flex justify-center items-center border-number">
                  {count1}
                </div>{" "}
              </div>
              <div className="w-1/2 ">
                <div className="w-[110px] h-[340px] text-[140px] bg-white rounded-xl font-semibold-tek flex justify-center items-center border-number">
                  {count2}
                </div>
              </div>
            </div>
          </div>
          <div className="relative top-0 w-full">
            <div className="absolute top-48 left-1/2 -translate-x-1/2 flex justify-end">
              <div className="relative w-full right-0">
                <img
                  src={BG_REWARD}
                  className="w-9/12 relative left-1/2 -translate-x-1/2"
                />
                <div className="absolute top-10">
                  <div className="relative left-1/2 -translate-x-1/2 w-10/12 sm:w-6/12 2xl:w-6/12 min-[2000px]:w-7/12">
                    <img src={spinDetails?.gift_image} className="" />
                    <div className="w-full text-center flex flex-col items-center">
                      <div className="font-bold-italic-so text-[20px]">
                        {spinDetails?.gift_name}
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <button
                  className="relative w-full flex justify-center -mt-12 hover:pointer"
                  onClick={disableAll === 0 ? handleFinished : handleDetails}
                >
                  <img
                    src={disableAll === 0 ? FINISH : PLAY}
                    className="w-7/12"
                  />
                </button>
              </div>
            </div>
          </div>

          {lstReward?.length !== 0 && (
            <div
              className={`${
                id === "1" ? "grid-cols-4" : "grid-cols-1"
              } fixed right-0 grid top-1/2 -translate-y-1/2 bg-[#E1ECF3] border-board p-5 rounded-tl-3xl rounded-bl-3xl`}
            >
              {lstReward?.map((res: any) => (
                <div className="col-span-1 p-2">
                  <div className="bg-[#F2FCFF] border-number font-semibold-tek text-center py-1 rounded-xl text-[#14A5A5] text-[40px] px-2 font-semibold-so">
                    {res?.code}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <Popup
          handleClose={handleOpenResult}
          status={spinDetails?.status_period}
        />
      )}
    </Fragment>
  );
}
