import "./App.css";
import { isLocalhost, isMobileDevice } from "./utils/deviceModel";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { userLocal } from "./services/localService/localService";
import { setAuthorization } from "./services/apiService/configURL";
import Home from "./pages/Home";
import DetailsReward from "./pages/DetailsReward";
import Home2 from "./pages/Home2";
import Login from "./pages/Login";
import Home3 from "./pages/Home3";
import YearAndParty from "./pages/YearAndParty";
import DetailsRewardYEP from "./pages/DetailsRewardYEP";

function App() {
  let access = false;
  document.body.style.backgroundColor = "white";
  let mobileDevice = isMobileDevice();

  let { token } = userLocal.get();
  setAuthorization(token);
  const queryClient = new QueryClient();
  return (
    <div className="font-regular-so ">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/yep/random" element={<YearAndParty />} />
            <Route
              path="/details-reward/yep/:id"
              element={<DetailsRewardYEP />}
            />
            <Route path="/vq1" element={<Home />} />
            <Route path="/vq2" element={<Home2 />} />
            <Route path="/vq3" element={<Home3 />} />
            <Route
              path="/details-reward/:type/:id"
              element={<DetailsReward />}
            />
          </Routes>
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
