import { useState } from "react";
import { homeServices } from "../services/apiService/homeService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setAuthorization } from "../services/apiService/configURL";

export default function Login() {
  const [usename, setUserName] = useState("");
  const navigation = useNavigate();
  const [pass, setPass] = useState("");
  const handleUserName = (e: any) => {
    setUserName(e.target.value);
  };
  const handlePass = (e: any) => {
    setPass(e.target.value);
  };
  const handleLogin = () => {
    console.log(usename);
    console.log(pass);
    homeServices
      .Login(usename, pass)
      .then((res: any) => {
        console.log(res);
        navigation(`/yep/random`);
        localStorage.setItem("TOKEN", res.token);
        setAuthorization(res.token);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };
  return (
    <div className="w-full">
      <section className="bg-white w-full">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold text-center leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Đăng nhập
              </h1>
              <div className="space-y-4 md:space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Tên đăng nhập
                  </label>
                  <input
                    name="login"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="UserName..."
                    onChange={handleUserName}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    onChange={handlePass}
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <button
                  onClick={handleLogin}
                  className="w-full border-btn text-black bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Đăng nhập
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
